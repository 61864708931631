.about-card_title {
    display:flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.about-card_width-text-clamp{
    max-width: 100rem;
    white-space: break-spaces;
}
.about-card_profile-img{
    width: 100%;
    height: 100%;
    max-height: 30rem;
    max-width: 20rem;
    object-fit: cover;
    float:left;
    margin-right: 2rem;
    margin-bottom: 1.5rem;

    @media (max-width: $xs){
        margin-right: auto;
        margin-left: auto;   
    }
    @media (max-width: $sm){
        margin-right: auto;
        margin-left: auto;   
    }
    @media (max-width: $md){
        margin-right: 2rem;
        margin-left: auto;
    }
}

.about-card_profile {

    @media (max-width: $xs){
        display: grid;
    }
    @media (max-width: $sm){
        display: grid;
    }
    @media (max-width: $md){
        display: grid;
    }

}