.footer_footer {
    background-color: $contactMeColour;
    min-height: unset;
}

.footer_icon-img{
    width: 50px;
    height: 50px;
}

.footer_icons-container{
    display: flex;
    gap: 1rem;
}